export function niceSeconds (seconds) {
	seconds = parseInt(seconds)

	let hours   = Math.floor(seconds / 3600)
	let minutes = Math.floor((seconds - hours * 3600) / 60)
	seconds = seconds - minutes * 60 - hours * 3600

	return (hours < 10 ? "0" : "") + hours + ":" + (minutes < 10 ? "0" : "") + minutes + ":" + (seconds < 10 ? "0" : "") + seconds
}

export function niceSecondsWithLabels (seconds) {
	seconds = parseInt(seconds)

	let hours   = Math.floor(seconds / 3600)
	let minutes = Math.floor((seconds - hours * 3600) / 60)
	seconds = seconds - minutes * 60 - hours * 3600

	return (hours > 0 ? hours + " ore, " : "") + (minutes > 0 ? minutes + " minuti," : "") + seconds + " secondi"
}

export function toMysqlDate (date) {
	return date.split("/").reverse().join("-")
}

export function formatDate (date) {
	if (!date) {
		return ""
	}

	let dateObject = new Date(date)
	let dateObjectDate  = dateObject.getDate()
	let dateObjectMonth = dateObject.getMonth() + 1
	let dateObjectYear  = dateObject.getFullYear()

	return (dateObjectDate < 10 ? "0" : "") + dateObjectDate + "/" + (dateObjectMonth < 10 ? "0" : "") + dateObjectMonth + "/" + dateObjectYear
}

export function formatDatetime (datetime) {
	if (!datetime) {
		return ""
	}

	let date = formatDate(datetime)
	let time = datetime.split(" ")[1]

	return date + " " + time
}

export function timeToSeconds (time) {
	let explodedTime = time.split(":")
	return explodedTime[0] * 3600 + explodedTime[1] * 60 + explodedTime[2]
}

export function currentDate () {
	let dateObject = new Date()

	let dateObjectYear  = dateObject.getFullYear()
	let dateObjectMonth = dateObject.getMonth() + 1
	let dateObjectDate  = dateObject.getDate()

	let dateParts = [
		dateObjectYear,
		(dateObjectMonth < 10 ? "0" : "") + dateObjectMonth,
		(dateObjectDate < 10 ? "0" : "") + dateObjectDate,
	]

	return dateParts.join("-")
}

export function currentTime () {
	let dateObject = new Date()

	let dateObjectHours   = dateObject.getHours()
	let dateObjectMinutes = dateObject.getMinutes()
	let dateObjectSeconds = dateObject.getSeconds()

	let dateParts = [
		(dateObjectHours   < 10 ? "0" : "") + dateObjectHours,
		(dateObjectMinutes < 10 ? "0" : "") + dateObjectMinutes,
		(dateObjectSeconds < 10 ? "0" : "") + dateObjectSeconds,
	]

	return dateParts.join(":")
}

export function currentDatetime () {
	return currentDate() + " " + currentTime()
}