<template>
	<div id="app" :class="{'is-updating': isUpdating }">
		<AppHeader></AppHeader>
		<div id="updates-available-view" class="container" v-if="hasAvailableUpdates">
			<div class="card mb-3">
				<div class="card-body">
					<p>
						Una nuova versione è disponibile.
					</p>

					<button class="btn btn-success w-100" type="button" @click="performUpdate()">
						Aggiorna
					</button>
				</div>
			</div>
		</div>
		<router-view v-else />
	</div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue'

export default {
	components: {
		AppHeader,
	},
	data () {
		return {
			isRefreshingServiceWorker: false,
			isUpdating: false,
			hasAvailableUpdates: false,
			serviceWorkerRegistration: null
		}
	},
	created () {
		document.addEventListener("serviceWorkerUpdated", this.showUpdatesAvailableUI, { once: true })

		navigator.serviceWorker.addEventListener("controllerchange", () => {
			if (this.isRefreshingServiceWorker) {
				return
			}

			this.isRefreshingServiceWorker = true

			window.location.reload()
		})
	},
	methods: {
		showUpdatesAvailableUI (event) {
			this.serviceWorkerRegistration = event.detail
			this.hasAvailableUpdates = true
		},
		performUpdate () {
			this.hasAvailableUpdates = false

			if (!this.serviceWorkerRegistration || !this.serviceWorkerRegistration.waiting) {
				return
			}

			this.isUpdating = true
			this.serviceWorkerRegistration.waiting.postMessage({ type: 'SKIP_WAITING' })
		}
	},
}
</script>

<style>
	body {
		background-color: #ecf0f1;
	}
	.text-nowrap {
		white-space: nowrap;
	}
	.text-right {
		text-align: right;
	}
	.table-vmiddle th,
	.table-vmiddle td {
		vertical-align: middle !important
	}
	.bg-blue   { background-color: #3498db !important;}
	.bg-green  { background-color: #2ecc71 !important;}
	.bg-orange { background-color: #e67e22 !important;}
	.bg-purple { background-color: #9b59b6 !important;}
	.bg-red    { background-color: #e74c3c !important;}
	.bg-yellow { background-color: #f1c40f !important;}
	.text-blue   { color: #3498db !important;}
	.text-green  { color: #2ecc71 !important;}
	.text-orange { color: #e67e22 !important;}
	.text-purple { color: #9b59b6 !important;}
	.text-red    { color: #e74c3c !important;}
	.text-yellow { color: #f1c40f !important;}
	.is-updating { filter: blur(2px) grayscale(1); }
</style>